import { GlobalNotification } from '@/components/GlobalNotification/_';
import { useModel } from '@@/exports';
import { gql, useApolloClient } from '@apollo/client';
import _ from 'lodash';

export const Notification: React.FC = () => {
  const requester = useApolloClient();
  const { initialState } = useModel('@@initialState');

  const typeOptions = [
    { label: 'Any', value: '-1' },
    { label: 'Info', value: '0', color: 'green' },
    { label: 'Major', value: '1', color: 'orange' },
    { label: 'Emergent', value: '2', color: 'red' },
  ];

  const appendData = async (status, tagType, pageNum) => {
    if (!initialState?.user) {
      return [];
    }

    const action = {
      searcher: [],
      parameters: {
        current: pageNum,
        pageSize: 10,
      },
    };
    if (status === 'unRead') {
      action.searcher?.push({
        key: 'confirmedAt',
        operator: '=',
        value: '',
      } as never);
    }
    if (tagType !== '-1') {
      action.searcher?.push({
        key: 'userMessageCategory.level',
        operator: '=',
        value: tagType,
      } as never);
    }

    if (tagType === '-1' && status !== 'unRead') {
      _.omit(action, ['searcher']);
    }

    return (
      await requester.query({
        query: gql`
          query Query($action: RetrieveUserMessagesAction!) {
            palmier {
              userMessage {
                retrieve(action: $action) {
                  total
                  data {
                    userMessageCategory {
                      level
                      name
                      parent {
                        name
                        level
                      }
                    }
                    createdAt
                    description
                    name
                    uuid
                    user {
                      name
                    }
                    shareType
                    owner {
                      name
                    }
                    confirmedAt
                  }
                }
              }
            }
          }
        `,
        variables: {
          action: action,
        },
        fetchPolicy: 'network-only',
      })
    ).data.palmier.userMessage.retrieve.data.map((item) => {
      return {
        ...item,
        title: item.name,
        datetime: item.createdAt,
        tag: item.userMessageCategory?.name,
        color:
          typeOptions &&
          typeOptions.find(
            (type) => type.value === item.userMessageCategory.level,
          ) &&
          typeOptions.find(
            (type) => type.value === item.userMessageCategory.level,
          ).color,
      };
    });
  };

  return (
    <GlobalNotification
      appendData={appendData}
      typeOptions={typeOptions}
      moreInfoPath="/operation-management/content-management/user-message"
      getUnRead={() =>
        requester.query({
          query: gql`
            query Query($action: RetrieveUserMessagesAction!) {
              palmier {
                userMessage {
                  retrieve(action: $action) {
                    total
                  }
                }
              }
            }
          `,
          variables: {
            action: {
              searcher: [
                {
                  key: 'confirmedAt',
                  value: '',
                  operator: '=',
                },
              ],
            },
          },
          fetchPolicy: 'network-only',
        })
      }
      confirmedMessages={(data: { uuid: string }[]) =>
        requester.mutate({
          mutation: gql`
            mutation Mutate($entities: [PartialUserMessage!]!) {
              palmier {
                userMessage {
                  update(entities: $entities) {
                    confirmedAt
                  }
                }
              }
            }
          `,
          variables: {
            entities: data,
          },
        })
      }
    />
  );
};
